<template>
	<div>
		<div class="tl-bar L-flex-row">
			<div class="L-container-96">
				<el-row type="flex" justify="space-between" align="middle">
					<el-col :span="20">
						<div class="L-flex-row">
							<img src="@/assets/icon_police.png" style="width: 20px;margin-right: 10px;">
							派出所巡检 {{mj_info.mj_name}} {{mj_info.mj_mobile}}
						</div>
						
					</el-col>
				</el-row>
				

			</div>
		</div>
		<div class="L-container-96 tl-margin-top">
			<el-input placeholder="请输入内容">
				<el-button slot="append" icon="el-icon-search"></el-button>
			</el-input>
		</div>

		<div class="tl-list-container L-margin-top-30">
			<div class="tl-list-item"  v-for="item in hotelList" :key="item.site_code" @click="toRoomList(item.site_code)">
				<el-row :gutter="10" type="flex" justify="start" align="center">
					<el-col :span="6" class="L-text-align-center">
						<i class="el-icon-s-shop icon"></i>
					</el-col>
					<el-col :span="14">
						<div>
							<el-badge :value="item.computer_zxsl" class="item">
								<div class="L-font-text" style="padding-right: 10px;">{{item.site_name}}</div>
							</el-badge>
						</div>
						<div class="L-margin-top-5">
							<span style="color:#67C23A">●</span>&nbsp;
							<span class="L-font-smtext L-font-color-777">吧台正常</span>
							&nbsp;
							<span style="color:#67C23A">●</span>&nbsp;
							&nbsp;
							<span class="L-font-smtext L-font-color-777">{{item.sjzt}}</span>
							&nbsp;
						</div>
					</el-col>
					<el-col :span="4" class="L-text-align-center">
						<i class="el-icon-arrow-right L-margin-top-10"></i>
					</el-col>
				</el-row>
			</div>
			
		</div>
	</div>

</template>

<script>
	export default{
		data(){
			return{
				hotelList:"",
				mj_info:"",
			}
		},
		methods:{
			toRoomList(site_code){
				this.$router.push({ path: '/policestation/roomList',query:{site_code:site_code}})
			}
		},
		mounted(){
			
			//获取宾馆列表
			this.$axios({
					method: "get",
					url: "/ctid/PhoneService/findDjHotelList",
				})
				.then(res => {
					this.hotelList = res.data.data;
					// console.log(this.hotelList);
				});
			//
			this.$axios({
				method:"get",
				url:"/ctid/PhoneService/findMjxx",
				params:{
					openid:123,
				}
			}).then(res=>{
				this.mj_info  = res.data.data;
				console.log(this.mj_info);
				
			})
		}
	}
</script>

<style>
	.tl-color {
		color: #409EFF
	}

	.L-container-96 {
		width: 96%;
		margin: 0px auto;
	}

	.L-container-90 {
		width: 90%;
		margin: 0px auto;
	}

	.L-container-80 {
		width: 80%;
		margin: 0px auto;
	}

	.L-flex-row {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}


	/* L-style */
	.L-row-space {
		width: 100%;
		height: 20px;
	}

	.L-row-nowrap {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.L-font-h1 {
		font-size: 40px;
	}

	.L-font-h2 {
		font-size: 35px;
	}

	.L-font-h3 {
		font-size: 30px;
	}

	.L-font-h4 {
		font-size: 25px;
	}

	.L-font-h5 {
		font-size: 20px;
	}

	.L-font-bgtext {
		font-size: 16px;
	}

	.L-font-text {
		font-size: 14px;
	}

	.L-font-smtext {
		font-size: 12px;
	}

	/* color */
	.L-font-color-gray {
		color: #95A5A6
	}

	.L-font-color-666 {
		color: #666;
	}

	.L-font-color-777 {
		color: #777;
	}

	.L-font-color-888 {
		color: #888;
	}

	.L-font-color-999 {
		color: #999;
	}

	.L-font-color-aaa {
		color: #aaa;
	}

	.L-font-color-bbb {
		color: #bbb;
	}

	.L-font-color-ccc {
		color: #ccc;
	}

	.L-font-color-ddd {
		color: #ddd;
	}

	.L-font-color-red {
		color: #F56C6C
	}

	.L-font-color-white {
		color: #fff;
	}

	/*  */
	.L-font-bold {
		font-weight: bold;
	}

	/* text-align */

	.L-text-align-center {
		text-align: center;
	}

	/*  */
	.L-row-90 {
		margin: 0 auto;
		width: 90%;
	}

	/* margin */
	.L-margin-top-10 {
		margin-top: 10px;
	}

	.L-margin-top-20 {
		margin-top: 20px;
	}

	.L-margin-top-30 {
		margin-top: 30px;
	}

	.L-margin-top-40 {
		margin-top: 40px;
	}

	.L-margin-top-50 {
		margin-top: 50px;
	}

	.L-margin-top-60 {
		margin-top: 60px;
	}

	.L-margin-top-70 {
		margin-top: 70px;
	}

	.L-margin-right-5 {
		margin-right: 5px;
	}

	.tl-margin-top {
		margin-top: 50px;
	}
	
	.tl-bar {
		position: fixed;
		top:0;
		left:0;
		width: 100%;
		font-size: 12px;
		color: #fff;
		height: 40px;
		line-height: 40px;
		background: #409EFF;
		z-index: 999;

	}
	
	

	.tl-list-container {}

	.tl-list-item {
		padding:15px 0;
		border-top: 1px dashed #eee;
	}

	.tl-list-item .icon {
		font-size: 40px;
		color: #409EFF;
	}
</style>
